import React, {Component, useState, useContext} from "react";
import ReactDom from "react-dom";
import { Editor } from "@tinymce/tinymce-react";
import {database} from "../../../utils/firebaseConfig";

const CreateCommentaires = () => {

  const [auteur, setAuteur] = useState("");
  const [societe, setSociete] = useState("");
  const [text, setText] = useState("");

  const createCommentaires = () => {
    const commentairesDB = database.ref("commentairesDB");
    const commentaire = {
      auteur,
      societe,
      text
    }

    commentairesDB.push(commentaire);

    setAuteur("");
    setSociete("");
    setText("");
  }

  return(
    <div className="create">
      <h4>Créer un témoignage client pour le site</h4>
      <div className="formCreate">
        <input
          type="text"
          name="auteur"
          value={auteur}
          onChange={(e) => setAuteur(e.target.value)}
          placeholder="Auteur du témoignage"
        />
        <input
          type="text"
          name="societe"
          value={societe}
          onChange={(e) => setSociete(e.target.value)}
          placeholder="Société de l'auteur"
        />
        <Editor
          initialValue="<p>texte du commentaire</p>"
          apiKey="58lhkkiysu2blzrgvgl8aqsbws6vvtexiw9hearrziwc4y1m"
          init={{
            height: 100,
            menubar: false,
            plugins: ['link image code'],
            toolbar:
            'bold italic | alignleft aligncenter alignright | code'
          }}
          onEditorChange={(content) => setText(content)}
        />
        <button onClick={createCommentaires}>créer</button>
      </div>
    </div>
  )
}

export default CreateCommentaires;
