import React, {Fragment, useEffect, useState, useContext} from "react";
import {Link} from "react-router-dom";
import ReactDom from "react-dom";

//fontawesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { faAddressCard } from "@fortawesome/free-solid-svg-icons";
import { faAddressBook } from "@fortawesome/free-solid-svg-icons";

const WelcomeAdmin = () => {

  return (
    <Fragment>
      <div className="welcomeContainer">
        <div className="headerWelcome">
          <h2>Accueil de votre tableau de bord</h2>
        </div>
        <div className="mainWelcome">
          <div className="tuilePageAcc">
            <Link to="/home">
              <h2><FontAwesomeIcon icon={faHome} /></h2>
              <h3>Page Accueil</h3>
            </Link>
          </div>
          <div className="tuilePageAbout">
            <Link to="/about">
              <h2><FontAwesomeIcon icon={faAddressCard} /></h2>
              <h3>Page A propos</h3>
            </Link>
          </div>
          <div className="tuilePageContact">
            <Link to="/contact">
              <h2><FontAwesomeIcon icon={faAddressBook} /></h2>
              <h3>Page Contact</h3>
            </Link>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default WelcomeAdmin;
