import firebase from "firebase";
import {database, auth} from "./firebaseConfig";

  class FirebaseService {
    constructor() {
      // Initialize Firebase
      this.db = database;
      this.auth = auth;
    }

    //inscription
    signUpUser = (email, password) => this.auth.createUserWithEmailAndPassword(email, password);


    //connexion
    loginUser = (email, password) => this.auth.signInWithEmailAndPassword(email, password);


    //deconnexion
    signOutUser = () => this.auth.signOut();

    //databaseUser
    user = uid => this.db.ref("accueilDB");
  }



export default FirebaseService;
