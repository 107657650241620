import React, {Component, useState, useContext} from "react";
import ReactDom from "react-dom";
import { Editor } from "@tinymce/tinymce-react";
import {database} from "../../../utils/firebaseConfig";
import ProgressBar from "../../progressBar";

const CreateArticles = () => {
  //text
  const [title, setTitle] = useState("");
  const [cloudWords, setCloudWords] = useState("");
  const [text, setText] = useState("");
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const types = ["image/png", "image/jpg", "image/jpeg"];
  let url = null;

  const createArticles = () => {
    const articlesDB = database.ref("articlesDB");
    //const databaseRef = database.ref("imagesDB");
    if(document.getElementById("imgProgressBar")){
      let urlImg = document.getElementById("imgProgressBar");
      url = urlImg.currentSrc;
    }
    const acticle = {
      title,
      cloudWords,
      text,
      url
    }

    articlesDB.push(acticle);

    setTitle("");
    setCloudWords("");
    setText("");
    setFile(null);
  }

  //image
  const handleChange = (e) => {
    let selected = e.target.files[0];
    if(selected && types.includes(selected.type)){
      setFile(selected);
      document.getElementById("placeholder").style.display = "none";
      setError(null);
    } else {
      setFile(null);
      setError("erreur : le fichier sélectionné n'est pas de type PNG ou JPG");
    }
  }

  return(
    <div className="create">
      <h4>Créer des articles principaux</h4>
      <div className="formCreate">
        <input
          type="text"
          name="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="titre de de l'article"
        />
        {/*<textarea
        value={text}
        onChange={(e) => setText(e.target.value)}
        placeholder="paragraphe de l'article"
        />*/}
        <Editor
          initialValue="<p>paragraphe de l'article</p>"
          apiKey="58lhkkiysu2blzrgvgl8aqsbws6vvtexiw9hearrziwc4y1m"
          //value={text}
          init={{
            height: 100,
            menubar: false,
            plugins: ['link image code'],
          toolbar:
            'bold italic | alignleft aligncenter alignright | code'
          }}
          onEditorChange={(content) => setText(content)}
        />
        <Editor
          initialValue="<p>le nuage de mots de l'article</p>"
          apiKey="58lhkkiysu2blzrgvgl8aqsbws6vvtexiw9hearrziwc4y1m"
          //value={text}
          init={{
            height: 100,
            menubar: false,
            plugins: ['link image code'],
          toolbar:
            'bold italic | alignleft aligncenter alignright | code'
          }}
          onEditorChange={(content) => setCloudWords(content)}
        />
        {/*<textarea
          type="text"
          name="cloudWords"
          value={cloudWords}
          onChange={(e) => setCloudWords(e.target.value)}
          placeholder="le nuage de mots de l'article"
        />*/}
        <div className="formImg">
          <div className="btnFileContainer">
            <input className="inputFile" id="btnFile" type="file" onChange={handleChange}/>
            <label for="btnFile" class="inputFileTrigger" tabindex="0">+</label>
          </div>
          <div>
            {file && <ProgressBar file={file} />}
            <img id="placeholder" src="https://via.placeholder.com/300x150" alt="upload_img" width="300px" height="auto"/>
          </div>
          {error && <div className="error">{error}</div>}
        </div>
        <button onClick={createArticles}>créer</button>
      </div>
    </div>
  )
}

export default CreateArticles;
