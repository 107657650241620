import React, {Fragment, useEffect, useState, useContext} from "react";
import ReactDom from "react-dom";
import CreateCommentaires from "./CreateCommentaires";
import ReadCommentaires from "./ReadCommentaires";

const  IndexAccueil = () => {
  return (
    <Fragment>
      <div className="crudContainer">
        <div className="sectionCreate"><CreateCommentaires /></div>
        <div className="sectionReadUpdate"><ReadCommentaires /></div>
      </div>
    </Fragment>
  )
}

export default IndexAccueil;
