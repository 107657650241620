import React, {Fragment, useState, useEffect} from "react";
import ReactDom from "react-dom";
import {database} from "../../../utils/firebaseConfig";
import UpdateDeletePartenaires from "./UpdateDeletePartenaires";

const ReadPartenaires = () => {

  const [partenaires, setPartenairesList] = useState([]);

  useEffect(() => {
    const partenairesDB = database.ref("partenairesDB");

    partenairesDB.on("value", (snapshot) => {
      let previousPartenairesList = snapshot.val();
      let partenairesList = [];
      for(let id in previousPartenairesList) {
        partenairesList.push({id, ...previousPartenairesList[id]})
      }
      setPartenairesList(partenairesList);
    })
  }, []);

  return (
    <Fragment>
      <div className="containerItems">
      {partenaires && partenaires.map((item, index) => (
        <UpdateDeletePartenaires item= {item} key={index}/>
      ))}
      </div>
    </Fragment>
  );
}

export default ReadPartenaires;
