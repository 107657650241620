import React, {Fragment, useState, useEffect} from "react";
import ReactDom from "react-dom";
import Parser from "html-react-parser";
import { database } from "../admin/utils/firebaseConfig";

import logoBNP from "../../img/logoBNPblanc.png";
import logoSG from "../../img/logoSGblanc.png";
import logoLoreal from"../../img/logoLorealblanc.png";
import logoEnedis from "../../img/logoEnedisblanc.png";
import logoEngie from "../../img/logoEngieblanc.png";

const About = () => {
  const [about, setAboutList] = useState([]);
  const [partenaires, setPartenairesList] = useState([]);

  useEffect(() => {
    const aboutDB = database.ref("aboutDB");
    const partenairesDB = database.ref("partenairesAboutDB");

    if (document.getElementById("about")) {
      document.querySelector(".headerContainer").className = "headerContainer scroll";
    } else {
      document.querySelector(".headerContainer").className = "headerContainer";
    }

    aboutDB.on("value", (snapshot) => {
      let previousAboutList = snapshot.val();
      let aboutList = [];
      for(let id in previousAboutList) {
        aboutList.push({id, ...previousAboutList[id]})
      }
      setAboutList(aboutList);
    })

    partenairesDB.on("value", (snapshot) => {
      let previousPartenairesList = snapshot.val();
      let partenairesList = [];
      for(let id in previousPartenairesList) {
        partenairesList.push({id, ...previousPartenairesList[id]})
      }
      setPartenairesList(partenairesList);
    })
  }, []);

  return (
    <Fragment>
      <div className="tuileContainer" id="about">
        {about && about.map((item, index) => (
          <Fragment>
            <div className="titleAbout">
              <h2>{item.title}</h2>
              <h3 className="none">Tech Practice</h3>
            </div>
            <div className="tableAbout">
              <div className="cellAbout cellLeft">
                <h3>{item.subTitle}</h3>
                {Parser(item.text)}
              </div>
              <div className="cellAbout cellRight">
                <h3>{item.subTitle2}</h3>
                {Parser(item.text2)}
              </div>
            </div>
          </Fragment>
        ))}
        <div className="logoAbout">
          {partenaires && partenaires.map((item, index) => (
            <div className="itemAbout"><img src={item.url} alt="partenairesAbout"/></div>
          ))}
        </div>
      </div>
    </Fragment>
  )
}

export default About;

/*export default class About extends React.Component {

  componentDidMount() {
    this.handlePage();
  }
  handlePage = () => {
    if (document.getElementById("about")) {
      document.querySelector(".headerContainer").className = "headerContainer scroll";
    } else {
      document.querySelector(".headerContainer").className = "headerContainer";
    }
  };

  render() {
    return (
      <Fragment>
        <div className="tuileContainer" id="about">
          <div className="titleAbout">
            <h2>A propos</h2>
            <h3 className="none">Tech Practice</h3>
          </div>
          <div className="tableAbout">
            <div className="cellAbout cellLeft">
              <h3>Qui somme nous ?</h3>
              <p>En tant qu’entreprise entièrement composée d’intervennant technique, nous avons assuré dans le choix de nos collaborateurs des phases de sélection poussées en vue de se démarquer comme entreprise d’expertise technique.</p>
              <p>Une entreprise de service spécialisée dans le domaine IT, le conseil avec une approche nouvelle.</p>
              <p>Une entreprise ou chaque intervenant connaît le milieu de l'informatique et chacun de ses problèmes étant tous sur le terrain faces à la réalité des choses.</p>
              <p>Nous avons écouté, accompagné. Nous avons conscience des problématiques de nos clients et savons porter attention à chaque point.</p>
              <p>Nous bénéficions pour avantage du cumule de nos projets</p>
            </div>
            <div className="cellAbout cellRight">
              <h3>Nos objectifs</h3>
              <p>Faire émerger de plus en plus ce nouveau type d'accompagnement ou le sur-mesure est privilégié à la masse, ce par une approche où le type de profil que vous cherchez est aussi celui qui vous accompagne dans la sélection du profil</p>
              <p>« qui peut mieux qualifier et présenter le métier que le métier lui-même »</p>
            </div>
          </div>
          <div className="logoAbout">
            <div className="itemAbout"><img src={logoBNP} alt="logoBNP"/></div>
            <div className="itemAbout" id="logoEnedis"><img src={logoEnedis} alt="logoEnedis"/></div>
            <div className="itemAbout"><img src={logoLoreal} alt="logoLoreal"/></div>
            <div className="itemAbout"><img src={logoEngie} alt="logoEngie"/></div>
            <div className="itemAbout"><img src={logoSG} alt="logoSG"/></div>
          </div>
        </div>
      </Fragment>
    );
  }
}*/
