import React, {Fragment, useEffect, useState, useContext} from "react";
import firebase, {FirebaseContext} from "./utils/Firebase";

const Deconnexion = () => {

  const firebase = useContext(FirebaseContext);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if(checked){
      firebase.signOutUser();
    }
  }, [checked, firebase]);

  const handleChange = event => {
    setChecked(event.target.checked);
  }

  return (
    <Fragment>
      <div className="deconnexion">
        <h5>se déconnecter</h5>
        <input
          onChange={handleChange}
          type="checkbox"
          checked={checked}
        />
      </div>
    </Fragment>
  )

}

export default Deconnexion;
