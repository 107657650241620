import React, {Fragment, useEffect, useState, useContext} from "react";
import {Link} from "react-router-dom";
import ReactDom from "react-dom";
import {database} from "../../utils/firebaseConfig";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNewspaper } from "@fortawesome/free-regular-svg-icons";

const IndexContact = () => {

  const [contact, setContactList] = useState([]);

  useEffect(() => {
    const contactDB = database.ref("contactDB")

    contactDB.on("value", (snapshot) => {
      let previousContactList = snapshot.val();
      let contactList = [];
      for(let id in previousContactList) {
        contactList.push({id, ...previousContactList[id]})
      }
      setContactList(aboutList);
    })
  }, []);

  return (
    <Fragment>
      <div className="headerHomeAdmin">
        <h2>Gestion de la page contact</h2>
      </div>
      <div className="mainHomeAdmin">
        <div className="tuileComm">
          <h4 className="count">{contact.length}</h4>
          <Link to="/contact/textContact">
            <h2><FontAwesomeIcon icon={faNewspaper} /></h2>
            <h3>Contenu principal</h3>
          </Link>
        </div>
      </div>
    </Fragment>
  )
}

export default IndexContact;
