import React, {Fragment} from "react";
import ReactDom from "react-dom";
import {Link} from "react-router-dom";

export default class Navigation extends React.Component {
  render() {
    return (
      <Fragment>
        <nav className="navigationPrincipale">
          <ul>
            <li>
              <Link to="/">Accueil</Link>
            </li>
            <li>
              <Link to="/about">A propos</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </nav>
      </Fragment>
    );
  }
}
