import React, {Fragment, useState, useEffect} from "react";
import ReactDom from "react-dom";
import {database} from "../../../utils/firebaseConfig";
import UpdateDeleteArticles from "./UpdateDeleteArticles";

const ReadArticles = () => {

  const [articles, setArticlesList] = useState([]);

  useEffect(() => {
    const articlesDB = database.ref("articlesDB");

    articlesDB.on("value", (snapshot) => {
      let previousArticlesList = snapshot.val();
      let articlesList = [];
      for(let id in previousArticlesList) {
        articlesList.push({id, ...previousArticlesList[id]})
      }
      setArticlesList(articlesList);
    })
  }, []);

  return (
    <Fragment>
      <div className="containerItems">
      {articles && articles.map((item, index) => (
        <UpdateDeleteArticles item= {item} key={index}/>
      ))}
      </div>
    </Fragment>
  );
}

export default ReadArticles;
