//fonctionnement
import React, {Fragment, useEffect, useState, useContext} from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import ReactDom from "react-dom";
import firebase, {FirebaseContext} from "./utils/Firebase";
import Deconnexion from "./Deconnexion";
import stylesAdmin from "../../stylesAdmin.scss";

import Navigation from "./components/navigation/Navigation";
import WelcomeAdmin from "./components/WelcomeAdmin";
//pages
import IndexHome from "./components/home";
import IndexAbout from "./components/about";
import IndexContact from "./components/contact";
//home rubriques
import IndexAccueil from "./components/home/accueil";
import IndexArticles from "./components/home/articles";
import IndexCommentaires from "./components/home/commentaires";
import IndexPartenaires from "./components/home/partenaires";
import IndexBigPicture from "./components/home/bigPicture";
import IndexAboutPrinc from "./components/about/commentaires";
import IndexPartenairesAbout from "./components/about/partenaires";
import IndexTextContact from "./components/contact/textContact";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from "@fortawesome/free-solid-svg-icons";


const IndexAdmin = props => {

  const firebase = useContext(FirebaseContext);
  const [userSession, setUserSession] = useState(null);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    if(document.getElementById("dashboard")){
      document.getElementById("headerContainer").style.display = "none";
      document.getElementById("footerContainer").style.display = "none";
    }
    let listener = firebase.auth.onAuthStateChanged(user => {
      user ? setUserSession(user) : props.history.push("/");
    })

    if(!!userSession){
      firebase.user(userSession.uid)
      .get()
      .then(doc =>{
        if(doc && doc.exists){
          const data = doc.data()
          setUserData(data)
        }
      })
      .catch(error =>{

      });
    }

    return () => {
      listener()
    };
  }, [userSession]);

  return userSession === null ? (
     <div id="dashboard">
      <h2>Accès refusé</h2>
     </div>
   ) : (
     <Fragment>
     <Router>
      <div className="dashboard" id="dashboard">
        <div className="headerDashboard">
          <h2>Dashboard</h2>
          <h4><FontAwesomeIcon icon={faUser} />  Bonjour {userSession.email}</h4>
          <div>
            <Deconnexion />
          </div>
        </div>
        <div className="bodyAdmin">
          <nav className="dashboardNav">
            <Navigation />
          </nav>
          <div className="dashboardMain">
            <Switch>
              <Route exact path="/IndexAdmin" component={WelcomeAdmin}/>
              <Route exact path="/home" component={IndexHome}/>
              <Route path="/home/accueil" component={IndexAccueil}/>
              <Route path="/home/articles" component={IndexArticles}/>
              <Route path="/home/bigPicture" component={IndexBigPicture}/>
              <Route path="/home/commentaires" component={IndexCommentaires}/>
              <Route path="/home/partenaires" component={IndexPartenaires}/>
              <Route path="/about/partenaires" component={IndexPartenairesAbout}/>
              <Route path="/about/commentaires" component={IndexAboutPrinc}/>
              <Route path="/contact/textContact" component={IndexTextContact}/>
              <Route exact path="/about" component={IndexAbout}/>
              <Route exact path="/contact" component={IndexContact}/>
            </Switch>
          </div>
        </div>
      </div>
    </Router>
    </Fragment>
   );
}

export default IndexAdmin;
