import React, {Component, useState, useContext} from "react";
import ReactDom from "react-dom";
import { Editor } from "@tinymce/tinymce-react";
import {database} from "../../../utils/firebaseConfig";

const CreateAbout = () => {

  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [text, setText] = useState("");
  const [subTitle2, setSubTitle2] = useState("");
  const [text2, setText2] = useState("");


  const createAbout = () => {
    const aboutDB = database.ref("aboutDB");
    const about = {
      title,
      subTitle,
      text,
      subTitle2,
      text2
    }

    aboutDB.push(about);

    setTile("");
    setSubTitle("");
    setText("");
    setSubTitle2("");
    setText2("");
  }

  return(
    <div className="create">
      <h4>Créer le contenu principal de la page à propos</h4>
      <div className="formCreate">
        <input
          type="text"
          name="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="titre de la page"
        />
        <input
          type="text"
          name="subTitle"
          value={subTitle}
          onChange={(e) => setSubTitle(e.target.value)}
          placeholder="titre de la première colonne"
        />
        <Editor
          initialValue="<p>texte de la première colonne</p>"
          apiKey="58lhkkiysu2blzrgvgl8aqsbws6vvtexiw9hearrziwc4y1m"
          init={{
            height: 100,
            menubar: false,
            plugins: ['link image code'],
            toolbar:
            'bold italic | alignleft aligncenter alignright | code'
          }}
          onEditorChange={(content) => setText(content)}
        />
        <input
          type="text"
          name="subTitle2"
          value={subTitle2}
          onChange={(e) => setSubTitle2(e.target.value)}
          placeholder="titre de la deuxième colonne"
        />
        <Editor
          initialValue="<p>texte de la deuxième colonne</p>"
          apiKey="58lhkkiysu2blzrgvgl8aqsbws6vvtexiw9hearrziwc4y1m"
          init={{
            height: 100,
            menubar: false,
            plugins: ['link image code'],
            toolbar:
            'bold italic | alignleft aligncenter alignright | code'
          }}
          onEditorChange={(content) => setText2(content)}
        />
        <button onClick={createAbout}>créer</button>
      </div>
    </div>
  )
}

export default CreateAbout;
