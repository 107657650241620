import React, {Fragment, useState, useEffect} from "react";
import ReactDom from "react-dom";
import {database} from "../../../utils/firebaseConfig";
import UpdateDeleteTextContact from "./UpdateDeleteTextContact";

const ReadTextContact = () => {

  const [contact, setContactList] = useState([]);

  useEffect(() => {
    const contactDB = database.ref("contactDB");

    contactDB.on("value", (snapshot) => {
      let previousContactList = snapshot.val();
      let contactList = [];
      for(let id in previousContactList) {
        contactList.push({id, ...previousContactList[id]})
      }
      setContactList(contactList);
    })
  }, []);

  return (
    <Fragment>
      <div className="containerItems">
      {contact && contact.map((item, index) => (
        <UpdateDeleteTextContact item= {item} key={index}/>
      ))}
      </div>
    </Fragment>
  );
}

export default ReadTextContact;
