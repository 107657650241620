import React, {useState, useEffect} from "react";
import {storage, database, timestamp} from "./firebaseConfig";

const useStorage = (file) => {
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(null);
  const [url, setUrl] = useState(null);

  useEffect(() => {
    const img = file;
    const storageRef = storage.ref(file.name);
    const databaseRef = database.ref("imagesDB");

    storageRef.put(file).on("state_changed",
    (snapshot) => {
      let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      setProgress(percentage);
    },
    (err) => {
      setError(err);
    },
    async () => {
      const url = await storageRef.getDownloadURL();
      const createdAt = timestamp;
      const file = {url, timestamp};
      databaseRef.push(file);
      setUrl(url);
    })
  }, [file]);

  return { progress, url, error}
}

export default useStorage;
