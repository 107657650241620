import React, {Fragment, Component, useState} from "react";
import {database} from "../../../utils/firebaseConfig";
import { Editor } from "@tinymce/tinymce-react";
import Parser from "html-react-parser";

//fontawesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenSquare } from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";

const UpdateDeleteCommentaires = ({item}) => {
  const [update, setUpdate] = useState(false);
  const [auteurUpdate, setAuteurUpdate] = useState(null);
  const [societeUpdate, setSocieteUpdate] = useState(null);
  const [textUpdate, setTextUpdate] = useState(null);

  const updateItem = () => {
    let commentaireItem = database.ref("commentairesDB").child(item.id);

    if(auteurUpdate !== null) {
      commentaireItem.update({
        auteur: auteurUpdate
      })
    }
    if(societeUpdate !== null) {
      commentaireItem.update({
        societe: societeUpdate
      })
    }
    if(textUpdate !== null) {
      commentaireItem.update({
        text: textUpdate
      })
    }

    setUpdate(false);
  }

  const deleteItem = () => {
    let commentaireItem = database.ref("commentairesDB").child(item.id);
    commentaireItem.remove();
  }

  return (
    <Fragment>
    {update === false && (
      <Fragment>
      <div className="itemContainer">
        <div className="itemAffichage">
          <h5>{item.auteur}</h5>
          <h5>{item.societe}</h5>
          {Parser(item.text)}
        </div>
        <div className="buttonContainer">
        <button className="orange" onClick={() => setUpdate(!update)}><FontAwesomeIcon icon={faPenSquare} /></button>
        <button className="red" onClick={deleteItem}><FontAwesomeIcon icon={faTrashAlt} /></button>
        </div>
      </div>
      </Fragment>
    )}

    {update && (
      <div className="updateContainer">
        <div className="formCreate">
          <input
            type="text"
            defaultValue={item.auteur}
            onChange={(e) => setAuteurUpdate(e.target.value)}
          />
          <input
            type="text"
            defaultValue={item.societe}
            onChange={(e) => setSocieteUpdate(e.target.value)}
          />
          <Editor
            initialValue={item.text}
            apiKey="58lhkkiysu2blzrgvgl8aqsbws6vvtexiw9hearrziwc4y1m"
            init={{
              height: 100,
              menubar: false,
              plugins: ['link image code'],
              toolbar:
              'bold italic | alignleft aligncenter alignright | code'
            }}
            onEditorChange={(content) => setTextUpdate(content)}
          />
        </div>
        <div className="buttonContainer">
          <button className="green" onClick={updateItem}><FontAwesomeIcon icon={faCheckSquare} /></button>
        </div>
      </div>
    )}
    </Fragment>
  );
}

export default UpdateDeleteCommentaires;
