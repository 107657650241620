import React, {Fragment, useState, useEffect} from "react";
import ReactDom from "react-dom";
import {database} from "../../../utils/firebaseConfig";
import UpdateDeleteAccueil from "./UpdateDeleteAccueil";

const ReadAccueil = () => {

  const [accueil, setAccueilList] = useState([]);

  useEffect(() => {
    const accueilDB = database.ref("accueilDB");

    accueilDB.on("value", (snapshot) => {
      let previousAcceuilList = snapshot.val();
      let accueilList = [];
      for(let id in previousAcceuilList) {
        accueilList.push({id, ...previousAcceuilList[id]})
      }
      setAccueilList(accueilList);

    })
  }, []);

  return (
    <Fragment>
      <div className="containerItems">
      {accueil && accueil.map((item, index) => (
        <UpdateDeleteAccueil item= {item} key={index}/>
      ))}
      </div>
    </Fragment>
  );
}

export default ReadAccueil;
