module.exports = {
  tuile1: {
    id: "bigData",
    image: "tuileBigData",
    title: "Big Data",
    text: "<p>Développement sur environnement distribué</p><p>Normalisation / nettoyage de données</p><p>Traitements distribués / Temps réel</p><p>Alimentation de Datalake</p><p>Gestion de volumétrie importante</p><p>Déploiement Cloud</p><p>Définition d’architecture big data</p><p>Traitement en streaming</p>",
    text2: "<p>SCALA </p><p> PYSPARK</p><p>PYTHON </p><p> JAVA </p><p> SPARK </p><p> KAFKA </p><p> OOZIE </p><p> SQOOP </p><p> HIVE </p><p> HDFS </p><p> HADOOP </p><p> YARN </p><p> HBASE </p><p> HUE </p><p> KIBANA </p><p> TERADATA </p><p> TALEND </p><p> HORTONWORKS</p><p>CLOUEDERA</p><p>MAPR </p><p> CASSANDRA </p><p> ELASTICSEARCH </p><p> LOGSTASH </p><p> PENTAHO</p>"
  },
  tuile2: {
    id: "developpeur",
    image: "tuileDev",
    title: "Développement",
    text: "<p>TDD / UAT  / TNR</p><p>DDD – BDD</p><p>Test d'intégration</p><p>SOLID</p><p>Design pattern</p><p>Développement (Back/ Front)</p><p>Intégration / Déploiement continue</p>",
    text2: "<p>JAVA</p><p>JAVA EE</p><p>JAVA CORE </p><p> SPRING </p><p> ANGULAR </p><p> BOOTSTRAP </p><p> REST </p><p> DOT NET</p><p>DOT NET CORE </p><p> ANGULAR </p><p> HIBERNATE </p><p> JAVASCRIPT</p><p>TYPESCRIPT </p><p> HTML CSS </p><p> JENKINS </p><p> GIT</p><p>GITLAB </p><p> AGIL </p><p> SOAP</p><p>JUNIT</p><p>JMETER </p><p> TOMCAT </p><p> JBOSS </p><p> SWAGGER </p><p> MYSQL </p><p> ORACLE  </p><p> POSTGRESQL </p><p> SQL SERVER</p>"
  },
  tuile3: {
    id: "DevOps",
    image: "tuileDevOps",
    title: "DevOps",
    text: "<p>Clouds - Orchestration - Container - Load Balancing</p><p>Déploiement et intégration continue</p><p>Installation / Configuration / Automatisation</p><p>Conteneurisation</p><p>Cloud / UnPremise</p><p>Mise en place d’infrastructure / Cluster (kubernetes-Docker etc..)</p><p>Monitoring / Gestion d’incident / TroobleShooting</p><p>Support N1, N2, N3</p>",
    text2: "<p>Docker </p><p> Kubernetes </p><p> Cloud AWS</p><p>AZURE</p><p>GCP</p><p>Openstack</p><p> Openshift </p><p> Ansible</p><p>AWX </p><p> Gitlab </p><p> Terraform </p><p> Vagrant </p><p> Gitlab </p><p>CI/CD </p><p> Jenkins </p><p> Zabbix </p><p> Prometheus </p><p> Grafana </p><p> Nagios </p><p> Centrion </p><p> Stack ELK (Elastic Search Logstash  Kibana) </p><p> Load balancer: HAProxy </p><p> OS :  GNU/Linux </p><p>  Unix (OSx -  SunOS) </p><p> Window</p>"
  },
  tuile4: {
    id: "dataScience",
    image: "tuileData",
    title: "Data Science / IA",
    text: "<p>Data Munging / Web Scraping</p><p>Collecte / Transformation  / Normalisation</p><p>Optimisation</p><p>Deep Learning</p><p>Data Mining / Machine Learchning</p><p>Inteligence Artificielle</p><p>Visualisation</p>",
    text2: ""
  },
  tuile5: {
    id:"adminSys",
    image: "tuileAdminSys",
    title: "Admin Système / Ingénieur de production",
    text: "<p>Monitoring</p><p>Automatisation</p><p>Support N1,N2,N3 / Gestion d’incidents</p><p>Exploitation</p><p>Mise en production / Deploiement (Cloud Public / Cloud Privé/ Unpremise)</p><p>Installation / Configuration</p><p>Gestion de securité</p>",
    text2: ""
  },
  tuile6: {
    id: "conseil",
    image: "tuileConseil",
    title: "Conseil",
    text: "<p>Réalisation de cahier des charges</p><p>Réalisation et conduite de plannings</p><p>Suivi et reporting</p><p>Présentations techniques</p><p>Formation au produit</p><p>Gestion des phases de test</p><p>Livraison et support</p><p>Encadrement d’équipes</p><p>Développement et support</p>",
    text2: ""
  }
}
