import React, {Fragment, useState, useEffect, useContext} from "react";
import ReactDom from "react-dom";
import Firebase, { FirebaseContext } from "./utils/Firebase";
import {Link} from "react-router-dom";
import IndexAdmin from "./IndexAdmin";

const ConnexionAdmin = (props) => {

  const firebase = useContext(FirebaseContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [btn, setBtn] = useState(false);
  const [error, setError] = useState("");


  useEffect(() => {
    if(password.length > 5 && email !== ""){
      setBtn(true)
    } else if(btn){
      setBtn(false);
    }
    if(document.getElementById("connexion")){
      document.getElementById("headerContainer").style.display = "none";
      document.getElementById("footerContainer").style.display = "none";
    }
  }, [password, email]);

  const handleSubmit = e => {
    e.preventDefault();
    firebase.loginUser(email, password)
    .then(user => {
      props.history.push("/IndexAdmin");
    }).catch(error => {
      setError(error);
      setEmail("");
      setPassword("");
    })
  }

  return (
    <div className="tuileContainer" id="connexion" style={{textAlign: 'center'}}>
      <form onSubmit={handleSubmit}>
        <div className="login-page">

          {error !== "" && <span>{error.message}</span>}

          <h2>connexion à l'administration de <span>Tech-Practice</span></h2>
          <input
            onChange={e => setEmail(e.target.value)}
            value={email}
            type="email"
            placeholder="Votre email"
            required
          />
          <input
            onChange={e => setPassword(e.target.value)}
            value={password}
            type="password"
            placeholder="Votre mot de passe"
            required
          />
          {btn ? <button>Connexion</button> : <button disabled>Connexion</button>}
        </div>
        </form>
    </div>
  );
};

export default ConnexionAdmin;
