import React, {Fragment, useState, useEffect} from "react";
import ReactDom from "react-dom";
import Images from "../services/Images";
import { database } from "../admin/utils/firebaseConfig";

const TuileImg = () => {

  const [partenaires, setPartenairesList] = useState([]);

  useEffect(() => {
    const partenairesDB = database.ref("partenairesDB");

    partenairesDB.on("value", (snapshot) => {
      let previousPartenairesList = snapshot.val();
      let partenairesList = [];
      for(let id in previousPartenairesList) {
        partenairesList.push({id, ...previousPartenairesList[id]})
      }
      setPartenairesList(partenairesList);
    })
  }, []);

  return (
    <Fragment>
      {partenaires && partenaires.map((item) => {
        return (
          <div className="galleryItem">
            <img src={item.url} alt="partenaires"/>
          </div>
        )
      })}
    </Fragment>
  )
}

export default TuileImg;

/*export default class TuileImg extends React.Component {
  render() {
    let regex = /blanc$/;
    if(regex.test(this.props.img)) {
      return (
        <div className="galleryItem" id={this.props.img}>
          <Images img={this.props.img}/>
        </div>
        );
    } else {
      return null;
    }
  }
}*/
