import React, {Fragment, useState, useEffect} from "react";
import {database} from "../../../utils/firebaseConfig";
import ProgressBar from "../../progressBar";

//fontawesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenSquare } from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";

const UpdateDeletePartenaires = ({item}) => {
  const [update, setUpdate] = useState(false);
  const [imgUpdate, setImgUpdate] = useState(null);
  const [fileUpdate, setFileUpdate] = useState(null);
  const [error, setError] = useState(null);
  const types = ["image/png", "image/jpg", "image/jpeg"];
  let url = null;

  const updateItem = () => {
    let partenairesItem = database.ref("partenairesDB").child(item.id);

    if(fileUpdate !== null) {
      if(document.getElementById("imgProgressBar")){
        let urlImg = document.getElementById("imgProgressBar");
        url = urlImg.currentSrc;
      }
      partenairesItem.update({
        url: url
      })
    }

    setUpdate(false);
  }

  const handleChange = (e) => {
    let selected = e.target.files[0];
    if(selected && types.includes(selected.type)){
      setFileUpdate(selected);
      setError(null);
      setTimeout(() => {
        let imgDefault = document.getElementById("defaultImg");
        console.log(imgDefault);
        imgDefault.style.display = "none";
      }, 1000);
    } else {
      setFileUpdate(null);
      setError("erreur : le fichier sélectionné n'est pas de type PNG ou JPG");
    }
  }

  const deleteItem = () => {
    let partenairesItem = database.ref("partenairesDB").child(item.id);
    partenairesItem.remove();
  }

  return (
    <Fragment>
    {update === false && (
      <Fragment>
      <div className="itemContainer">
        <div className="itemAffichage partenaires">
          <img src={item.url} alt="image upload"  width="300px" height="auto"/>
        </div>
        <div className="buttonContainer">
        <button className="orange" onClick={() => setUpdate(!update)}><FontAwesomeIcon icon={faPenSquare} /></button>
        <button className="red" onClick={deleteItem}><FontAwesomeIcon icon={faTrashAlt} /></button>
        </div>
      </div>
      </Fragment>
    )}

    {update && (
      <div className="updateContainer">
        <div className="formCreate partenaires">
          <div className="formImg">
            <div className="btnFileContainer">
              <input className="inputFile" id="btnFileUpdate" type="file" onChange={handleChange}/>
              <label for="btnFileUpdate" class="inputFileTrigger" tabindex="0">+</label>
            </div>
            <div>
              <img src={item.url} id="defaultImg" alt="default_img" width="300px" height="auto" />
              {error && <div className="error">{error}</div>}
              {fileUpdate && <ProgressBar file={fileUpdate} />}
            </div>
          </div>
        </div>
        <div className="buttonContainer">
          <button className="green" onClick={updateItem}><FontAwesomeIcon icon={faCheckSquare} /></button>
        </div>
      </div>
    )}
    </Fragment>
  );
}

export default UpdateDeletePartenaires;
