import React, {Fragment, useEffect, useState, useContext} from "react";
import ReactDom from "react-dom";
import CreateArticles from "./CreateArticles";
import ReadArticles from "./ReadArticles";

const  IndexArticles = () => {
  return (
    <Fragment>
      <div className="crudContainer">
        <div className="sectionCreate"><CreateArticles /></div>
        <div className="sectionReadUpdate"><ReadArticles /></div>
      </div>
    </Fragment>
  )
}

export default IndexArticles;
