import React, {Fragment, useEffect, useState, useContext} from "react";
import ReactDom from "react-dom";
import CreateAccueil from "./CreateAccueil";
import ReadAccueil from "./ReadAccueil";

const  IndexAccueil = () => {
  return (
    <Fragment>
      <div className="crudContainer">
        <div className="sectionCreate"><CreateAccueil /></div>
        <div className="sectionReadUpdate"><ReadAccueil /></div> 
      </div>
    </Fragment>
  )
}

export default IndexAccueil;
