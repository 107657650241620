import React from "react";
import useStorage from "../utils/useStorage";

const ProgressBar = ({file}) => {
  const {url, progress} = useStorage(file);

  return progress === 100 ? (
    <div>
      <div className="progressBar" style={{background:"none"}}></div>
      <img id="imgProgressBar" src={url} alt="upload_img" width="300px" height="auto"/>
    </div>
  ):(
    <div className="progressBar" style={{width: progress + "%" }}></div>
  )

}

export default ProgressBar;
