import React, {Fragment, useState, useEffect} from "react";
import ReactDom from "react-dom";
import {database} from "../../../utils/firebaseConfig";
import UpdateDeleteBigPicture from "./UpdateDeleteBigPicture";

const ReadBigPicture = () => {

  const [bigPicture, setBigPictureList] = useState([]);

  useEffect(() => {
    const bigPictureDB = database.ref("bigPictureDB");

    bigPictureDB.on("value", (snapshot) => {
      let previousBigPictureList = snapshot.val();
      let bigPictureList = [];
      for(let id in previousBigPictureList) {
        bigPictureList.push({id, ...previousBigPictureList[id]})
      }
      setBigPictureList(bigPictureList);
    })
  }, []);

  return (
    <Fragment>
      <div className="containerItems">
      {bigPicture && bigPicture.map((item, index) => (
        <UpdateDeleteBigPicture item= {item} key={index}/>
      ))}
      </div>
    </Fragment>
  );
}

export default ReadBigPicture;
