import React, {Fragment, useState, useEffect} from "react";
import ReactDom from "react-dom";
import { database } from "../admin/utils/firebaseConfig";
import Parser from "html-react-parser";

const TuileBigImgText = () => {

  const [bigPicture, setBigPictureList] = useState([]);

  useEffect(() => {
    const bigPictureDB = database.ref("bigPictureDB");

    bigPictureDB.on("value", (snapshot) => {
      let previousBigPictureList = snapshot.val();
      let bigPictureList = [];
      for(let id in previousBigPictureList) {
        bigPictureList.push({id, ...previousBigPictureList[id]})
      }
      setBigPictureList(bigPictureList);
    })
  }, []);

  return (
    <Fragment>
      {bigPicture && bigPicture.map((item) => {
        return (
          <div className="tuileBigImgContainer">
            <div className="tuileBigImgTitle">
              <h2>{item.title}</h2>
              <p>{item.subTitle}</p>
              <h3 className="none">Tech Practice</h3>
            </div>
            <img src={item.url} alt="bigPicture"/>
            <div className="tuileBigImgText">
              {Parser(item.text)}
            </div>
          </div>
        )
      })}
    </Fragment>
  )
}

export default TuileBigImgText;
