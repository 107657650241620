import React, {Fragment} from "react";
import ReactDom from "react-dom";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import "regenerator-runtime/runtime";
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';

import css from "../styles.scss";

import Header from "./header/Header";
import Footer from "./footer/Footer";

import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";

import ConnexionAdmin from "./admin/ConnexionAdmin";
import IndexAdmin from "./admin/IndexAdmin";
import ErrorPage from "./pages/ErrorPage";
import Credits from "./pages/Credits";

function App() {
  return (
      <Fragment>
        <Router>
          <Header/>

          <Switch>
            <Route exact path="/" component={Home}/>
            <Route path="/about" component={About}/>
            <Route path="/contact" component={Contact}/>
            <Route path="/credits" component={Credits}/>

            <Route path ="/admin" component={ConnexionAdmin}/>
            <Route path ="/indexAdmin" component={IndexAdmin}/>
            <Route component={ErrorPage}/>
          </Switch>

          <Footer/>
        </Router>
      </Fragment>
    )
}

export default App;
