import React, {Fragment} from "react";
import ReactDom from "react-dom";
import firebase, { FirebaseContext } from "./components/admin/utils/Firebase";
import App from "./components/App";
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';



ReactDom.render(
  <FirebaseContext.Provider value={new firebase()}>
    <App/>
  </FirebaseContext.Provider>,
  document.getElementById('app')
)
