import React, {useState, useContext} from "react";
import ReactDom from "react-dom";
import {database} from "../../../utils/firebaseConfig";

const CreateAccueil = () => {

  const [title, setTitle] = useState("");
  const [keywords, setKeywords] = useState("");

  const createAccueil= () => {
    const accueilDB = database.ref("accueilDB");
    const accueil = {
      title,
      keywords
    }

    accueilDB.push(accueil);

    setTitle("");
    setKeywords("");
  }

  return(
    <div className="create">
      <h4>Créer un message d'accueil pour le site</h4>
      <div className="formCreate">
        <input
          type="text"
          name="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="titre de l'accueil"
        />
        <textarea
        value={keywords}
        onChange={(e) => setKeywords(e.target.value)}
        placeholder="liste de mots clés"
        />
        <button onClick={createAccueil}>créer</button>
      </div>

    </div>
  )
}

export default CreateAccueil;
