import React, {Fragment} from "react";
import ReactDom from "react-dom";


export default class ErrorPage extends React.Component {

  componentDidMount() {
    this.handlePage();
  }
  handlePage = () => {
    if (document.getElementById("errorPage")) {
      document.querySelector(".headerContainer").className = "headerContainer";
    } else {
      document.querySelector(".headerContainer").className = "headerContainer";
    }
  };

  render() {
    return (
      <Fragment>
        <div className="tuileContainer" id="errorPage">
          <h2>Error 404</h2>
          <p>La page demandée est introuvable.</p>
        </div>
      </Fragment>
    );
  }
}
