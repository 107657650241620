import React, {Fragment, useEffect, useState, useContext} from "react";
import ReactDom from "react-dom";
import CreatePartenaires from "./CreatePartenaires";
import ReadPartenaires from "./ReadPartenaires";

const  IndexPartenairesAbout = () => {
  return (
    <Fragment>
      <div className="crudContainer">
        <div className="sectionCreate"><CreatePartenaires /></div>
        <div className="sectionReadUpdate"><ReadPartenaires /></div>
      </div>
    </Fragment>
  )
}

export default IndexPartenairesAbout;
