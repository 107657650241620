import React, {Fragment} from "react";
import ReactDom from "react-dom";
import bdd from "../../bddArticle";
import bddImage from "../../bddImage";
import bddTexte from "../../bddText";
import up from "../../img/up.svg";

import TuileAccueil from "../tuiles/TuileAccueil";
import TuileImg from "../tuiles/TuileImg";
import TuileText from "../tuiles/TuileText";
import TuileImgText from "../tuiles/TuileImgText";
import TuileBigImgText from "../tuiles/TuileBigImgText";

export default class Home extends React.Component {
  constructor(){
    super();
    this.state = {
      ...bdd
    }

    this.stateImg = {
      ...bddImage
    }

    /*this.stateText = {
      ...bddTexte
    }*/
  }

  componentDidMount() {
    this.handlePage();
  }
  handlePage = () => {
    if (document.getElementById("home")) {
      document.querySelector(".headerContainer").className = "headerContainer";
    } else {
      document.querySelector(".headerContainer").className = "headerContainer";
    }
  };

  scrollToTop() {
  window.scrollTo({top: 0, behavior: "smooth"});
  }

  render() {
    const imgTexts = Object
      .keys(this.state)
      .map(imgText => <TuileImgText key={imgText} details={this.state[imgText]}/>);

    const bddImgs = Object
      .keys(this.stateImg)
      .map(bddImg => <TuileImg key={bddImg} img={this.stateImg[bddImg]}/>);

    /*const bddTexts = Object
      .keys(this.stateText)
      .map(bddText => <TuileText key={bddText} txt={this.stateText[bddText]}/>);*/

    return (
      <Fragment>
        <TuileAccueil />

        <div className="homeTuilesPrincipales" id="home">
          {/*imgTexts*/}
          <TuileImgText />
        </div>

        <div className="tuileBigImgTextContainer">
          <TuileBigImgText />
          {/*<TuileBigImgText txt={bddTexte.txt1} img={bddImage.img2}/>*/}
        </div>

        <div className="galleryContainer">
          <div className="galleryTitle">
            <h3>Nos Clients nous font confiance</h3>
          </div>
          <div className="tuileContainer" id="gallery">
            {/*bddImgs*/}
            <TuileImg />
          </div>
        </div>

        <div className="tuileContainer" id="commentairesContainer">
          <TuileText />
        </div>
        <button id="buttonToTop" onClick={this.scrollToTop}><span>TOP</span><img src={up} alt="up"></img></button>
      </Fragment>
    );
  }
}
