import React, {Fragment} from "react";
import ReactDom from "react-dom";
import logo from "../../img/logo.png";

import Navigation from "../navigation/Navigation";

export default class Header extends React.Component {

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    if (window.scrollY > 200) {
      document.getElementById("buttonToTop").className = "block";
      document.querySelector(".headerContainer").className = "headerContainer scroll";
    } else {
      document.getElementById("buttonToTop").className = "none";
      document.querySelector(".headerContainer").className = "headerContainer";
    }
  };

  render() {
    return (
      <Fragment>
          <div className="headerContainer" id="headerContainer">
            <div className= "headerBackground">
              <div className="title">
                <a href="/"><img src={logo} alt="logo"></img></a>
                <h1>Tech Practice</h1>
              </div>
              <div className="navigation">
                <Navigation />
                <div className="borderBottom"></div>
              </div>
            </div>
          </div>
      </Fragment>
    );
  }
}
