import React, {Fragment, Component, useState, useEffect} from "react";
import {database} from "../../../utils/firebaseConfig";
import { Editor } from "@tinymce/tinymce-react";
import ProgressBar from "../../progressBar";
import Parser from "html-react-parser";

//fontawesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenSquare } from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";

const UpdateDeleteBigPicture = ({item}) => {
  const [update, setUpdate] = useState(false);
  const [titleUpdate, setTitleUpdate] = useState(null);
  const [subTitleUpdate, setSubTitleUpdate] = useState(null);
  const [textUpdate, setTextUpdate] = useState(null);
  const [imgUpdate, setImgUpdate] = useState(null);
  const [fileUpdate, setFileUpdate] = useState(null);
  const [error, setError] = useState(null);
  const types = ["image/png", "image/jpg", "image/jpeg"];
  let url = null;

  const updateItem = () => {
    let bigPictureItem = database.ref("bigPictureDB").child(item.id);

    if(titleUpdate !== null) {
      bigPictureItem.update({
        title: titleUpdate
      })
    }
    if(subTitleUpdate !== null) {
      bigPictureItem.update({
        subTitle: subTitleUpdate
      })
    }
    if(textUpdate !== null) {
      bigPictureItem.update({
        text: textUpdate
      })
    }
    if(fileUpdate !== null) {
      if(document.getElementById("imgProgressBar")){
        let urlImg = document.getElementById("imgProgressBar");
        url = urlImg.currentSrc;
      }
      bigPictureItem.update({
        url: url
      })
    }

    setUpdate(false);
  }

  const handleChange = (e) => {
    let selected = e.target.files[0];
    if(selected && types.includes(selected.type)){
      setFileUpdate(selected);
      setError(null);
      setTimeout(() => {
        let imgDefault = document.getElementById("defaultImg");
        console.log(imgDefault);
        imgDefault.style.display = "none";
      }, 1000);
    } else {
      setFileUpdate(null);
      setError("erreur : le fichier sélectionné n'est pas de type PNG ou JPG");
    }
  }

  const deleteItem = () => {
    let bigPictureItem = database.ref("bigPictureDB").child(item.id);
    bigPictureItem.remove();
  }

  return (
    <Fragment>
    {update === false && (
      <Fragment>
      <div className="itemContainer">
        <div className="itemAffichage">
          <h4>{item.title}</h4>
          <h5>{item.subTitle}</h5>
          {Parser(item.text)}
          <img src={item.url} alt="image upload"  width="300px" height="auto"/>
        </div>
        <div className="buttonContainer">
        <button className="orange" onClick={() => setUpdate(!update)}><FontAwesomeIcon icon={faPenSquare} /></button>
        <button className="red" onClick={deleteItem}><FontAwesomeIcon icon={faTrashAlt} /></button>
        </div>
      </div>
      </Fragment>
    )}

    {update && (
      <div className="updateContainer">
        <div className="formCreate">
          <input
            type="text"
            defaultValue={item.title}
            onChange={(e) => setTitleUpdate(e.target.value)}
          />
          <textarea
            type="text"
            defaultValue={item.subTitle}
            onChange={(e) => setSubTitleUpdate(e.target.value)}
          />
          <Editor
            initialValue={item.text}
            apiKey="58lhkkiysu2blzrgvgl8aqsbws6vvtexiw9hearrziwc4y1m"
            init={{
              height: 100,
              menubar: false,
              plugins: ['link image code'],
              toolbar:
              'bold italic | alignleft aligncenter alignright | code'
            }}
            onEditorChange={(content) => setTextUpdate(content)}
          />
          <div className="formImg">
            <div className="btnFileContainer">
              <input className="inputFile" id="btnFileUpdate" type="file" onChange={handleChange}/>
              <label for="btnFileUpdate" class="inputFileTrigger" tabindex="0">+</label>
            </div>
            <div>
              <img src={item.url} id="defaultImg" alt="default_img" width="300px" height="auto" />
              {error && <div className="error">{error}</div>}
              {fileUpdate && <ProgressBar file={fileUpdate} />}
            </div>
          </div>
        </div>
        <div className="buttonContainer">
          <button className="green" onClick={updateItem}><FontAwesomeIcon icon={faCheckSquare} /></button>
        </div>
      </div>
    )}
    </Fragment>
  );
}

export default UpdateDeleteBigPicture;
