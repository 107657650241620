import React, {Fragment, useState, useEffect} from "react";
import ReactDom from "react-dom";
import Parser from "html-react-parser";
import { database } from "../admin/utils/firebaseConfig";

const TuileText = () => {

  const [commentaires, setCommentairesList] = useState([]);

  useEffect(() => {
    const commentairesDB = database.ref("commentairesDB");

    commentairesDB.on("value", (snapshot) => {
      let previousCommentairesList = snapshot.val();
      let commentairesList = [];
      for(let id in previousCommentairesList) {
        commentairesList.push({id, ...previousCommentairesList[id]})
      }
      setCommentairesList(commentairesList);

    })
  }, []);

  return (
    <Fragment>
      {commentaires && commentaires.map((item) => {
        return (
          <div className="commentaires">
            <h3>{item.auteur} - <span>{item.societe}</span></h3>
            <div className="borderBottom3"></div>
            {Parser(item.text)}
          </div>
        )
      })}
    </Fragment>
  )
}

export default TuileText;

/*export default class TuileText extends React.Component {
  render() {
    let regex = /^"/;
    if(this.props.txt.id !== "txtHome1" && this.props.txt.auteur !== undefined) {
      return (
        <div className="commentaires">
          <h3>{Parser(this.props.txt.auteur)}</h3>
          <div className="borderBottom3"></div>
          <p>{Parser(this.props.txt.text)}</p>
        </div>
        );
    } else {
      return null;
    }
  }
}*/
