module.exports= {
  img1: "logo",
  img2: "bigImg1",
  img3: "logoBNPblanc",
  img4: "logoChanelblanc",
  img5: "logoCAblanc",
  img6: "logoEDFblanc",
  img7: "logoEnedisblanc",
  img8: "logoEngieblanc",
  img9: "logoLVblanc",
  img10: "logoSGblanc",
  img11:"logoLorealblanc",
}
