import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyAQ02Mgu8s-4Lhb_xcVlY3NI1QJDWDsLR8",
  authDomain: "tech-practice-21e95.firebaseapp.com",
  databaseURL: "https://tech-practice-21e95-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "tech-practice-21e95",
  storageBucket: "tech-practice-21e95.appspot.com",
  messagingSenderId: "394085230750",
  appId: "1:394085230750:web:5b946e6e449abe860a738f"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();
const database = firebase.database();
const auth = firebase.auth();
const timestamp = firebase.database.ServerValue.TIMESTAMP;

export {storage, database, auth, timestamp, firebase as default}

//export default firebase;
