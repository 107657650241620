import React, {Fragment, useEffect, useState, useContext} from "react";
import {Link} from "react-router-dom";
import ReactDom from "react-dom";
import {database} from "../../utils/firebaseConfig";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshake } from "@fortawesome/free-regular-svg-icons";
import { faNewspaper } from "@fortawesome/free-regular-svg-icons";

const IndexAbout = () => {

  const [partenaires, setPartenairesList] = useState([]);
  const [about, setAboutList] = useState([]);

  useEffect(() => {
    const partenairesDB = database.ref("partenairesAboutDB");
    const aboutDB = database.ref("aboutDB")

    partenairesDB.on("value", (snapshot) => {
      let previousPartenairesList = snapshot.val();
      let partenairesList = [];
      for(let id in previousPartenairesList) {
        partenairesList.push({id, ...previousPartenairesList[id]})
      }
      setPartenairesList(partenairesList);
    })

    aboutDB.on("value", (snapshot) => {
      let previousAboutList = snapshot.val();
      let aboutList = [];
      for(let id in previousAboutList) {
        aboutList.push({id, ...previousAboutList[id]})
      }
      setAboutList(aboutList);
    })
  }, []);

  return (
    <Fragment>
      <div className="headerHomeAdmin">
        <h2>Gestion de la page A propos</h2>
      </div>
      <div className="mainHomeAdmin">
        <div className="tuileComm">
          <h4 className="count">{about.length}</h4>
          <Link to="/about/commentaires">
            <h2><FontAwesomeIcon icon={faNewspaper} /></h2>
            <h3>Contenu principal</h3>
          </Link>
        </div>
        <div className="tuilePart">
          <h4 className="count">{partenaires.length}</h4>
          <Link to="/about/partenaires">
            <h2><FontAwesomeIcon icon={faHandshake} /></h2>
            <h3>Bandeau Partenaires</h3>
          </Link>
        </div>
      </div>
    </Fragment>
  )
}

export default IndexAbout;
