import React, {Fragment, useEffect, useState, useContext} from "react";
import ReactDom from "react-dom";
import CreateAbout from "./CreateAbout";
import ReadAbout from "./ReadAbout";

const  IndexAboutPrinc = () => {
  return (
    <Fragment>
      <div className="crudContainer">
        <div className="sectionCreate"><CreateAbout /></div>
        <div className="sectionReadUpdate"><ReadAbout /></div>
      </div>
    </Fragment>
  )
}

export default IndexAboutPrinc;
