import React, {Fragment, useState, useEffect} from "react";
import ReactDom from "react-dom";
import Particles from "react-particles-js";
import { database } from "../admin/utils/firebaseConfig";

const TuileAccueil = () => {

  const [accueil, setAccueilList] = useState([]);

  useEffect(() => {
    const accueilDB = database.ref("accueilDB");

    accueilDB.on("value", (snapshot) => {
      let previousAcceuilList = snapshot.val();
      let accueilList = [];
      for(let id in previousAcceuilList) {
        accueilList.push({id, ...previousAcceuilList[id]})
      }
      setAccueilList(accueilList);

    })
  }, []);

  return (
    <Fragment>
      <div className="TuileAccueil">
        <div className="bgAccueil">
        </div>
        <div id="particlesJs">
        <Particles
          params={{
            particles: {
              number: {
                value: 200,
                density: {
                  enable: true,
                  value_area: 2000,
                }
              },
            },
          }}
        />
        </div>
        <div className="bannerBackground"></div>
        <div className="banner">
          {accueil && accueil.map((item) => {
            return <h2>{item.title}</h2>
          })}
          <div className="borderBottom"></div>
          <div className="bannerText">
          {accueil && accueil.map((item) => {
            let accueilKeywords = Object;
            accueilKeywords = item.keywords.split(" ");
            let keyword = accueilKeywords.map((keyword) => {
              return <p>{keyword}</p>
            })
            return keyword
          })}
          </div>

        </div>
      </div>
    </Fragment>
  );
}

export default TuileAccueil;
