import React, {Fragment} from "react";
import ReactDom from "react-dom";
import {Link} from "react-router-dom";

//fontawesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTachometerAlt } from "@fortawesome/free-solid-svg-icons";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { faAddressCard } from "@fortawesome/free-solid-svg-icons";
import { faAddressBook } from "@fortawesome/free-solid-svg-icons";
import { faNewspaper } from "@fortawesome/free-regular-svg-icons";
import { faImages } from "@fortawesome/free-regular-svg-icons";
import { faHandshake } from "@fortawesome/free-regular-svg-icons";
import { faComments } from "@fortawesome/free-regular-svg-icons";

export default class Navigation extends React.Component {
  render() {
    return (
      <Fragment>
        <nav className="navPrincAdmin">
          <ul>
            <li className="linkDashboard"><Link to="/indexAdmin"><FontAwesomeIcon icon={faTachometerAlt} />  Tableau de bord</Link></li>
            <li className="linkHome"><Link to="/home"><FontAwesomeIcon icon={faHome} />  Page Accueil</Link>
              <ul className="subNavPrincAdmin">
                <li><Link to ="/home/accueil"><FontAwesomeIcon icon={faHome} />  Accueil</Link></li>
                <li><Link to ="/home/articles"><FontAwesomeIcon icon={faNewspaper} />  Articles</Link></li>
                <li><Link to ="/home/bigPicture"><FontAwesomeIcon icon={faImages} />  Big picture</Link></li>
                <li><Link to ="/home/partenaires"><FontAwesomeIcon icon={faHandshake} />  Partenaires</Link></li>
                <li><Link to ="/home/commentaires"><FontAwesomeIcon icon={faComments} />  Commentaires</Link></li>
              </ul>
            </li>
            <li><Link to="/about"><FontAwesomeIcon icon={faAddressCard} />  Page A propos</Link>
              <ul className="subNavPrincAdmin">
                <li><Link to="/about/commentaires"><FontAwesomeIcon icon={faNewspaper} />  Contenu principal</Link></li>
                <li><Link to="/about/partenaires"><FontAwesomeIcon icon={faHandshake} />  Bandeau Partenaires</Link></li>
              </ul>
            </li>
            <li><Link to="/contact"><FontAwesomeIcon icon={faAddressBook} />  Page Contact</Link>
              <ul className="subNavPrincAdmin">
                <li><Link to="/contact/textContact"><FontAwesomeIcon icon={faNewspaper} />  Contenu principal</Link></li>
              </ul>
            </li>
          </ul>
        </nav>
      </Fragment>
    );
  }
}
