import React, {Fragment, useState, useEffect} from "react";
import ReactDom from "react-dom";
import {database} from "../../../utils/firebaseConfig";
import UpdateDeleteCommentaires from "./UpdateDeleteCommentaires";

const ReadCommentaires = () => {

  const [commentaires, setCommentairesList] = useState([]);

  useEffect(() => {
    const commentairesDB = database.ref("commentairesDB");

    commentairesDB.on("value", (snapshot) => {
      let previousCommentairesList = snapshot.val();
      let commentairesList = [];
      for(let id in previousCommentairesList) {
        commentairesList.push({id, ...previousCommentairesList[id]})
      }
      setCommentairesList(commentairesList);
    })
  }, []);

  return (
    <Fragment>
      <div className="containerItems">
      {commentaires && commentaires.map((item, index) => (
        <UpdateDeleteCommentaires item= {item} key={index}/>
      ))}
      </div>
    </Fragment>
  );
}

export default ReadCommentaires;
