import React from "react";
import ReactDom from "react-dom";

import logo from "../../img/logo.png";

import tuileBigData from "../../img/bigData.jpg";
import tuileDev from "../../img/dev.jpg";
import tuileDevOps from "../../img/devOps.jpg";
import tuileData from "../../img/data.jpg";
import tuileAdminSys from "../../img/adminSys.jpg";
import tuileConseil from "../../img/conseil.jpg";

import bigImg1 from "../../img/bigImg1.jpg";

import logoBNP from "../../img/logoBNPblanc.png";
import logoChanel from "../../img/logoChanelblanc.png";
import logoCA from "../../img/logoCreditAgricoleblanc.png";
import logoEDF from "../../img/logoEDFblanc.png";
import logoEnedis from "../../img/logoEnedisblanc.png";
import logoEngie from "../../img/logoEngieblanc.png";
import logoLV from "../../img/logoLouisVuittonblanc.png";
import logoSG from "../../img/logoSGblanc.png";
import logoLoreal from"../../img/logoLorealblanc.png";

export default class Images extends React.Component {
  render() {
    let img = this.props.img;

    switch (img) {
      case "tuileBigData":
        return (<img src={tuileBigData} alt="tuileBigData"/>);
        break;
      case "tuileDev":
        return (<img src={tuileDev} alt="tuileDev"/>);
        break;
      case "tuileDevOps":
        return (<img src={tuileDevOps} alt="tuileDevOps"/>);
        break;
      case "tuileData":
        return (<img src={tuileData} alt="tuileData"/>);
        break;
      case "tuileAdminSys":
        return (<img src={tuileAdminSys} alt="tuileAdminSys"/>);
        break;
      case "tuileConseil":
        return (<img src={tuileConseil} alt="tuileConseil"/>);
        break;
      case "logo":
        return (<img src={logo} alt="logo"/>);
        break;
      case "bigImg1":
        return (<img src={bigImg1} alt="bigImg1"/>);
        break;
      case "logoBNPblanc":
        return (<img src={logoBNP} alt="logoBNP"/>);
        break;
      case "logoChanelblanc":
        return (<img src={logoChanel} alt="logoChanel"/>);
        break;
      case "logoCAblanc":
        return (<img src={logoCA} alt="logoCA"/>);
        break;
      case "logoEDFblanc":
        return (<img src={logoEDF} alt="logoEDF"/>);
        break;
      case "logoEnedisblanc":
        return (<img src={logoEnedis} alt="logoEnedis"/>);
        break;
      case "logoEngieblanc":
        return (<img src={logoEngie} alt="logoEngie"/>);
        break;
      case "logoLVblanc":
        return (<img src={logoLV} alt="logoLV"/>);
        break;
      case "logoSGblanc":
        return (<img src={logoSG} alt="logoSG"/>);
        break;
      case "logoLorealblanc":
        return (<img src={logoLoreal} alt="logoSG"/>);
        break;
      default:
        return "";
    }
  }
}
