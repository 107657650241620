import React, {Fragment, useState, useEffect} from "react";
import ReactDom from "react-dom";
import {database} from "../../../utils/firebaseConfig";
import UpdateDeleteAbout from "./UpdateDeleteAbout";

const ReadAbout = () => {

  const [about, setAboutList] = useState([]);

  useEffect(() => {
    const aboutDB = database.ref("aboutDB");

    aboutDB.on("value", (snapshot) => {
      let previousAboutList = snapshot.val();
      let aboutList = [];
      for(let id in previousAboutList) {
        aboutList.push({id, ...previousAboutList[id]})
      }
      setAboutList(aboutList);
    })
  }, []);

  return (
    <Fragment>
      <div className="containerItems">
      {about && about.map((item, index) => (
        <UpdateDeleteAbout item= {item} key={index}/>
      ))}
      </div>
    </Fragment>
  );
}

export default ReadAbout;
