import React, {Fragment, Component, useState} from "react";
import {database} from "../../../utils/firebaseConfig";
import { Editor } from "@tinymce/tinymce-react";
import Parser from "html-react-parser";

//fontawesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenSquare } from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";

const UpdateDeleteTextContact = ({item}) => {
  const [update, setUpdate] = useState(false);
  const [titleUpdate, setTitleUpdate] = useState(null);
  const [textUpdate, setTextUpdate] = useState(null);
  const [text2Update, setText2Update] = useState(null);

  const updateItem = () => {
    let contactItem = database.ref("contactDB").child(item.id);

    if(titleUpdate !== null) {
      contactItem.update({
        title: titleUpdate
      })
    }
    if(textUpdate !== null) {
      contactItem.update({
        text: textUpdate
      })
    }
    if(text2Update !== null) {
      contactItem.update({
        text2: text2Update
      })
    }

    setUpdate(false);
  }

  const deleteItem = () => {
    let contactItem = database.ref("contactDB").child(item.id);
    contactItem.remove();
  }

  return (
    <Fragment>
    {update === false && (
      <Fragment>
      <div className="itemContainer" id="aboutAdmin">
        <div className="itemAffichage">
          <h5>{item.title}</h5>
          {Parser(item.text)}
          {Parser(item.text2)}
        </div>
        <div className="buttonContainer">
        <button className="orange" onClick={() => setUpdate(!update)}><FontAwesomeIcon icon={faPenSquare} /></button>
        <button className="red" onClick={deleteItem}><FontAwesomeIcon icon={faTrashAlt} /></button>
        </div>
      </div>
      </Fragment>
    )}

    {update && (
      <div className="updateContainer" id="aboutAdmin">
        <div className="formCreate">
          <input
            type="text"
            defaultValue={item.title}
            onChange={(e) => setTitleUpdate(e.target.value)}
          />
          <Editor
            initialValue={item.text}
            apiKey="58lhkkiysu2blzrgvgl8aqsbws6vvtexiw9hearrziwc4y1m"
            init={{
              height: 100,
              menubar: false,
              plugins: ['link image code'],
              toolbar:
              'bold italic | alignleft aligncenter alignright | code'
            }}
            onEditorChange={(content) => setTextUpdate(content)}
          />
          <Editor
            initialValue={item.text2}
            apiKey="58lhkkiysu2blzrgvgl8aqsbws6vvtexiw9hearrziwc4y1m"
            init={{
              height: 100,
              menubar: false,
              plugins: ['link image code'],
              toolbar:
              'bold italic | alignleft aligncenter alignright | code'
            }}
            onEditorChange={(content) => setText2Update(content)}
          />
        </div>
        <div className="buttonContainer">
          <button className="green" onClick={updateItem}><FontAwesomeIcon icon={faCheckSquare} /></button>
        </div>
      </div>
    )}
    </Fragment>
  );
}

export default UpdateDeleteTextContact;
