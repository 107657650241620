module.exports= {
  txt1: {
    id: "txtHome1",
    title:"Développeurs",
    text:"<p>Démocratiser le concepte d’entreprise entièrement technique et opérationnel, continuant à cumulés les bons retours chez nos clients.</p><p>Plus que la fidélisation, rendre indépendant nos clients, poussant vers l’évolution plus que la maintenance.</p>"
  },
  txt2: {
    auteur: "Sebastien - <span>Engie</span>",
    text: "« Analyse de fond, des attentions à chaque remarque. Ils ne prennent pas nos attentes à la légère, très satisfait ! »",
  },
  txt3: {
    auteur: "Boris - <span>Société Générale</span>",
    text: "« Les profils conforment l’expertise que témoigne leur cv. Le concept d’un tech lead pour identifier nos besoins techniques et y présenter des profils eux même sélectionnés par des tech lead. Pas très répandu aujourd’hui mais très efficace ! Je recommande Tech Practice ! »",
  },
  txt4: {
    auteur: "François - <span>EDF</span>",
    text: "« Après déjà 6 mois avec un de vos développeurs, très satisfait, je vous ressoliciterait prochainement »",
  },
  txt5: {
    auteur:"Alia - <span>Louis Vuitton</span>",
    text: "« Les deux profils qu’ils nous ont envoyés remplissent non seulement haut la main le sujet pour lequel nous avions besoins d’intervenants, mais ils ont su s’appuyer des questions sur nos environnements et nos projet pour que ces profils puissent aussi intervenir sur d’autre sujets. Aujourd’hui leur 2 profils avancent chacun sur 3 à 4 sujets différents »"
  }
}
