import React, {Component, useState, useContext} from "react";
import ReactDom from "react-dom";
import { Editor } from "@tinymce/tinymce-react";
import {database} from "../../../utils/firebaseConfig";
import ProgressBar from "../../progressBar";

const CreateBigPicture = () => {
  //text
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [text, setText] = useState("");
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const types = ["image/png", "image/jpg", "image/jpeg"];
  let url = null;

  const createBigPicture = () => {
    const bigPictureDB = database.ref("bigPictureDB");
    //const databaseRef = database.ref("imagesDB");
    if(document.getElementById("imgProgressBar")){
      let urlImg = document.getElementById("imgProgressBar");
      url = urlImg.currentSrc;
    }
    const bigPicture = {
      title,
      subTitle,
      text,
      url
    }

    bigPictureDB.push(bigPicture);

    setTitle("");
    setSubTitle("");
    setText("");
    setFile(null);
  }

  //image
  const handleChange = (e) => {
    let selected = e.target.files[0];
    if(selected && types.includes(selected.type)){
      setFile(selected);
      document.getElementById("placeholder").style.display = "none";
      setError(null);
    } else {
      setFile(null);
      setError("erreur : le fichier sélectionné n'est pas de type PNG ou JPG");
    }
  }

  return(
    <div className="create">
      <h4>Créer la rubrique big picture</h4>
      <div className="formCreate">
        <input
          type="text"
          name="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="titre de la rubrique"
        />
        <textarea
          type="text"
          name="subTitle"
          value={subTitle}
          onChange={(e) => setSubTitle(e.target.value)}
          placeholder="sous-titre de la rubrique"
        />
        <Editor
          initialValue="<p>paragraphe de la rubrique</p>"
          apiKey="58lhkkiysu2blzrgvgl8aqsbws6vvtexiw9hearrziwc4y1m"
          init={{
            height: 100,
            menubar: false,
            plugins: ['link image code'],
            toolbar:
            'bold italic | alignleft aligncenter alignright | code'
          }}
          onEditorChange={(content) => setText(content)}
        />
        <div className="formImg">
          <div className="btnFileContainer">
            <input className="inputFile" id="btnFile" type="file" onChange={handleChange}/>
            <label for="btnFile" class="inputFileTrigger" tabindex="0">+</label>
          </div>
          <div>
            {file && <ProgressBar file={file} />}
            {/*<div className="progressBar" style={{background:"#D6D6D6"}}></div>*/}
            <img id="placeholder" src="https://via.placeholder.com/300x150" alt="upload_img" width="300px" height="auto"/>
          </div>
          {error && <div className="error">{error}</div>}
        </div>
        <button onClick={createBigPicture}>créer</button>
      </div>
    </div>
  )
}

export default CreateBigPicture;
