import React, {Fragment, useState} from "react";
import {database} from "../../../utils/firebaseConfig";

//fontawesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenSquare } from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";

const UpdateDeleteAccueil = ({item}) => {
  const [update, setUpdate] = useState(false);
  const [titleUpdate, setTitleUpdate] = useState(null);
  const [keywordsUpdate, setKeywordsUpdate] = useState(null);

  const updateItem = () => {
    let accueilItem = database.ref("accueilDB").child(item.id);

    if(titleUpdate !== null) {
      accueilItem.update({
        title: titleUpdate
      })
    }
    if(keywordsUpdate !== null) {
      accueilItem.update({
        keywords: keywordsUpdate
      })
    }

    setUpdate(false);
  }

  const deleteItem = () => {
    let accueilItem = database.ref("accueilDB").child(item.id);
    accueilItem.remove();
  }

  return (
    <Fragment>
    {update === false && (
      <Fragment>
      <div className="itemContainer">
        <div className="itemAffichage">
          <h5>{item.title}</h5>
          <p>{item.keywords}</p>
        </div>
        <div className="buttonContainer">
        <button className="orange" onClick={() => setUpdate(!update)}><FontAwesomeIcon icon={faPenSquare} /></button>
        <button className="red" onClick={deleteItem}><FontAwesomeIcon icon={faTrashAlt} /></button>
        </div>
      </div>
      </Fragment>
    )}

    {update && (
      <div className="updateContainer">
        <div className="formCreate">
          <input
            type="text"
            defaultValue={item.title}
            onChange={(e) => setTitleUpdate(e.target.value)}
          />
          <textarea
            defaultValue={item.keywords}
            onChange={(e) => setKeywordsUpdate(e.target.value)}
          />
        </div>
        <div className="buttonContainer">
          <button className="green" onClick={updateItem}><FontAwesomeIcon icon={faCheckSquare} /></button>
        </div>
      </div>
    )}
    </Fragment>
  );
}

export default UpdateDeleteAccueil;
