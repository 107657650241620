import React, {Fragment} from "react";
import ReactDom from "react-dom";
import Navigation from "../navigation/Navigation";
import {Link} from "react-router-dom";
import logo from "../../img/logo.png";

export default class Footer extends React.Component {
  render() {
    return (
      <Fragment>
        <footer className ="footerContainer" id="footerContainer">
          <div className="upFooter">
            <div className="title">
              <img src={logo} alt="logo"></img>
              <h2>Tech Practice</h2>
            </div>
            <div className="contactFooter">
              <a href="mailto:group@tech-practice.fr">nous contacter</a>
            </div>
            <div className="navigation">
              <Navigation />
            </div>
          </div>
          <div className="downFooter">
            <Link to="/credits">Mentions Légales</Link>
            <p>Tech Practice®</p>
          </div>
        </footer>
      </Fragment>
    );
  }
}
