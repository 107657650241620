import React, {useState, useContext} from "react";
import ReactDom from "react-dom";
import {database} from "../../../utils/firebaseConfig";
import ProgressBar from "../../progressBar";

const CreatePartenaires = () => {
  //text
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const types = ["image/png", "image/jpg", "image/jpeg"];
  let url = null;

  const createPartenaires = () => {
    const partenairesDB = database.ref("partenairesDB");
    //const databaseRef = database.ref("imagesDB");
    if(document.getElementById("imgProgressBar")){
      let urlImg = document.getElementById("imgProgressBar");
      url = urlImg.currentSrc;
    }
    const partenaires = {url}

    partenairesDB.push(partenaires);

    setFile(null);
  }

  //image
  const handleChange = (e) => {
    let selected = e.target.files[0];
    if(selected && types.includes(selected.type)){
      setFile(selected);
      document.getElementById("placeholder").style.display = "none";
      setError(null);
    } else {
      setFile(null);
      setError("erreur : le fichier sélectionné n'est pas de type PNG ou JPG");
    }
  }

  return(
    <div className="create">
      <h4>Créer la rubrique partenaires</h4>
      <div className="formCreate">
        <div className="formImg">
          <div className="btnFileContainer">
            <input className="inputFile" id="btnFile" type="file" onChange={handleChange}/>
            <label for="btnFile" class="inputFileTrigger" tabindex="0">+</label>
          </div>
          <div>
            {file && <ProgressBar file={file} />}
            {/*<div className="progressBar" style={{background:"#D6D6D6"}}></div>*/}
            <img id="placeholder" src="https://via.placeholder.com/300x150" alt="upload_img" width="300px" height="auto"/>
          </div>
          {error && <div className="error">{error}</div>}
        </div>
        <button onClick={createPartenaires}>créer</button>
      </div>
    </div>
  )
}

export default CreatePartenaires;
