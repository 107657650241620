import React, {Fragment, useState, useEffect} from "react";
import ReactDom from "react-dom";
import Images from "../services/Images";
import Parser from "html-react-parser";
import { database } from "../admin/utils/firebaseConfig";

const TuileImgText = () => {

  const [articles, setArticlesList] = useState([]);

  useEffect(() => {
    const articlesDB = database.ref("articlesDB");

    articlesDB.on("value", (snapshot) => {
      let previousArticlesList = snapshot.val();
      let articlesList = [];
      for(let id in previousArticlesList) {
        articlesList.push({id, ...previousArticlesList[id]})
      }
      setArticlesList(articlesList);
    })
  }, []);

  return (
    <Fragment>
      {articles && articles.map((item, index) => (
        <Fragment>
          <div className="tuileContainer" id="tuileHome">
            <div className="imgContainer">
              <img src={item.url} alt="articles"/>
            </div>
            <div className="textContainer">
              <div className="text">
                <h3>{item.title}</h3>
                <div className="textPrinc">{Parser(item.text)}</div>
              </div>
              {item.cloudWords === false && (
                <div className="techno"  style={{display: "none"}}></div>
              )}
              {item.cloudWords && (
                <div className="techno">
                  <div className="textTechno">{Parser(item.cloudWords)}</div>
                </div>
              )}

            </div>
          </div>
        </Fragment>
      ))}
    </Fragment>
  )
}

export default TuileImgText;

/*export default class TuileImgText extends React.Component {
  render() {
    return (
      <Fragment>
        <div className="tuileContainer" id="tuileHome">
          <div className="imgContainer">
            <Images img={this.props.details.image}/>
          </div>
          <div className="textContainer" id={this.props.details.id}>
            <div className="text">
              <h3>{this.props.details.title}</h3>
              <div className="textPrinc">{Parser(this.props.details.text)}</div>
            </div>
            <div className="techno">
              <div className="textTechno">{Parser(this.props.details.text2)}</div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}*/
