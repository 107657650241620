import React, {Component, useState, useContext} from "react";
import ReactDom from "react-dom";
import { Editor } from "@tinymce/tinymce-react";
import {database} from "../../../utils/firebaseConfig";

const CreateTextContact = () => {

  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [text2, setText2] = useState("");


  const createTextContact = () => {
    const contactDB = database.ref("contactDB");
    const contact = {
      title,
      text,
      text2
    }

    contactDB.push(contact);

    setTitle("");
    setText("");
    setText2("");
  }

  return(
    <div className="create">
      <h4>Créer le contenu principal de la page à propos</h4>
      <div className="formCreate">
        <input
          type="text"
          name="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="titre de la page"
        />
        <Editor
          initialValue="<p>texte sous le titre de la page</p>"
          apiKey="58lhkkiysu2blzrgvgl8aqsbws6vvtexiw9hearrziwc4y1m"
          init={{
            height: 100,
            menubar: false,
            plugins: ['link image code'],
            toolbar:
            'bold italic | alignleft aligncenter alignright | code'
          }}
          onEditorChange={(content) => setText(content)}
        />
        <Editor
          initialValue="<p>texte de l'encart</p>"
          apiKey="58lhkkiysu2blzrgvgl8aqsbws6vvtexiw9hearrziwc4y1m"
          init={{
            height: 100,
            menubar: false,
            plugins: ['link image code'],
            toolbar:
            'bold italic | alignleft aligncenter alignright | code'
          }}
          onEditorChange={(content) => setText2(content)}
        />
        <button onClick={createTextContact}>créer</button>
      </div>
    </div>
  )
}

export default CreateTextContact;
