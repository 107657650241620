import React, {Fragment} from "react";
import ReactDom from "react-dom";
import {Link} from "react-router-dom";


export default class ErrorPage extends React.Component {

  componentDidMount() {
    this.handlePage();
  }
  handlePage = () => {
    if (document.getElementById("credits")) {
      document.querySelector(".headerContainer").className = "headerContainer";
    } else {
      document.querySelector(".headerContainer").className = "headerContainer";
    }
  };

  render() {
    return (
      <Fragment>
        <div className="tuileContainer" id="credits">
          <div className="titlePage">
            <h2>Crédits</h2>
            <h3 className="none">Tech Practice</h3>
          </div>
          <div className="creditsGroup">
            <h3>Crédits photos</h3>
            <ul>
              <li>Pixabay - <span>Free-Photos</span></li>
              <li>Pixabay - <span>Jarmoluk</span></li>
              <li>Pixabay - <span>Pexels</span></li>
              <li>Pixabay - <span>Activedia</span></li>
              <li>Pexels - <span>Rodolfo Quirós</span></li>
              <li>Pexels - <span>ThisIsEngineering</span></li>
              <li>Pexels - <span>fauxels</span></li>
              <li>Pexels - <span>Christina Morillo</span></li>
              <li>freepik - <span>pch.vector</span></li>
            </ul>
          </div>
          <div className="creditsGroup">
            <h3>Crédits vidéo</h3>
            <ul>
              <li>Pixabay - <span>NewHumanDesigns</span></li>
            </ul>
          </div>
          <div className="creditsGroup">
            <Link to="/admin">Connexion</Link>
          </div>
        </div>
      </Fragment>
    );
  }
}
