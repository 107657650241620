import React, {Fragment, useEffect, useState, useContext} from "react";
import ReactDom from "react-dom";
import CreateBigPicture from "./CreateBigPicture";
import ReadBigPicture from "./ReadBigPicture";

const  IndexBigPicture = () => {
  return (
    <Fragment>
      <div className="crudContainer">
        <div className="sectionCreate"><CreateBigPicture /></div>
        <div className="sectionReadUpdate"><ReadBigPicture /></div> 
      </div>
    </Fragment>
  )
}

export default IndexBigPicture;
