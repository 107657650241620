import React, {Fragment, useEffect, useState, useContext} from "react";
import {Link} from "react-router-dom";
import ReactDom from "react-dom";
import {database} from "../../utils/firebaseConfig";

//fontawesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { faNewspaper } from "@fortawesome/free-regular-svg-icons";
import { faImages } from "@fortawesome/free-regular-svg-icons";
import { faHandshake } from "@fortawesome/free-regular-svg-icons";
import { faComments } from "@fortawesome/free-regular-svg-icons";

const IndexHome = () => {

  const [articles, setArticlesList] = useState([]);
  const [commentaires, setCommentairesList] = useState([]);
  const [partenaires, setPartenairesList] = useState([]);
  const [accueil, setAccueilList] = useState([]);
  const [bigPicture, setBigPictureList] = useState([]);

  useEffect(() => {
    const accueilDB = database.ref("accueilDB");
    const articlesDB = database.ref("articlesDB");
    const bigPictureDB = database.ref("bigPictureDB");
    const commentairesDB = database.ref("commentairesDB");
    const partenairesDB = database.ref("partenairesDB");

    accueilDB.on("value", (snapshot) => {
      let previousAcceuilList = snapshot.val();
      let accueilList = [];
      for(let id in previousAcceuilList) {
        accueilList.push({id, ...previousAcceuilList[id]})
      }
      setAccueilList(accueilList);

    })

    articlesDB.on("value", (snapshot) => {
      let previousArticlesList = snapshot.val();
      let articlesList = [];
      for(let id in previousArticlesList) {
        articlesList.push({id, ...previousArticlesList[id]})
      }
      setArticlesList(articlesList);
    })

    bigPictureDB.on("value", (snapshot) => {
      let previousBigPictureList = snapshot.val();
      let bigPictureList = [];
      for(let id in previousBigPictureList) {
        bigPictureList.push({id, ...previousBigPictureList[id]})
      }
      setBigPictureList(bigPictureList);
    })

    commentairesDB.on("value", (snapshot) => {
      let previousCommentairesList = snapshot.val();
      let commentairesList = [];
      for(let id in previousCommentairesList) {
        commentairesList.push({id, ...previousCommentairesList[id]})
      }
      setCommentairesList(commentairesList);
    })

    partenairesDB.on("value", (snapshot) => {
      let previousPartenairesList = snapshot.val();
      let partenairesList = [];
      for(let id in previousPartenairesList) {
        partenairesList.push({id, ...previousPartenairesList[id]})
      }
      setPartenairesList(partenairesList);
    })
  }, []);

  return (
    <Fragment>
      <div className="homeAdminContainer">
        <div className="headerHomeAdmin">
          <h2>Gestion de la page Home</h2>
        </div>
        <div className="mainHomeAdmin">
          <div className="tuileAcc">
            <h4 className="count">{accueil.length}</h4>
            <Link to="/home/accueil">
              <h2><FontAwesomeIcon icon={faHome} /></h2>
              <h3>Section Accueil</h3>
            </Link>
          </div>
          <div className="tuileArt">
            <h4 className="count">{articles.length}</h4>
            <Link to="/home/articles">
              <h2><FontAwesomeIcon icon={faNewspaper} /></h2>
              <h3>Section Acticles Principaux</h3>
            </Link>
          </div>
          <div className="tuileBigPic">
            <h4 className="count">{bigPicture.length}</h4>
            <Link to="/home/bigPicture">
              <h2><FontAwesomeIcon icon={faImages} /></h2>
              <h3>Section Big picture</h3>
            </Link>
          </div>
          <div className="tuilePart">
            <h4 className="count">{partenaires.length}</h4>
            <Link to="/home/partenaires">
              <h2><FontAwesomeIcon icon={faHandshake} /></h2>
              <h3>Section Partenaires</h3>
            </Link>
          </div>
          <div className="tuileComm">
            <h4 className="count">{commentaires.length}</h4>
            <Link to="/home/commentaires">
              <h2><FontAwesomeIcon icon={faComments} /></h2>
              <h3>Section Commentaires</h3>
            </Link>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default IndexHome;
