import React, {Fragment, useState, useEffect} from "react";
import ReactDom from "react-dom";
import Parser from "html-react-parser";
import emailjs from "emailjs-com";
import contact from "../../img/contactUs.jpg";
import { database } from "../admin/utils/firebaseConfig";

const Contact = () => {

  const [contact, setContactList] = useState([]);

  useEffect(() => {
    const contactDB = database.ref("contactDB");

    if (document.getElementById("contact")) {
      document.querySelector(".headerContainer").className = "headerContainer scroll";
    } else {
      document.querySelector(".headerContainer").className = "headerContainer";
    }

    contactDB.on("value", (snapshot) => {
      let previousContactList = snapshot.val();
      let contactList = [];
      for(let id in previousContactList) {
        contactList.push({id, ...previousContactList[id]})
      }
      setContactList(contactList);
    })
  }, []);

  const onButtonClick = () => {
    //e.preventDefault();
    document.querySelector(".contactName").innerHTML = "";
  }

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_h3e321g', 'template_5y70yao', e.target, 'user_ognUqk44qPgYOk7Tb3nJ7')
      .then((result) => {
          e.target.reset();
          document.querySelector(".success").className = "success block";
          setTimeout(() => {
              document.querySelector(".success").className = "success none";
            },3000);

      }, (error) => {
          e.target.reset();
          document.querySelector(".error").className = "error block";
          setTimeout(() => {
              document.querySelector(".error").className = "error none";
            },3000);
      });
  }

  return (
    <Fragment>
      <div className="backgroundContact">
        <div className="backgroundContactColor">
        <div className="tuileContainer" id="contact">
          <div className="ContactTitle">
            {contact && contact.map((item, index) => (
              <h2>{item.title}</h2>
            ))}
            <h3 className="none">Tech Practice</h3>
            <div className="borderBottom4"></div>
            {contact && contact.map((item, index) => (
              <Fragment>{Parser(item.text)}</Fragment>
            ))}
          </div>
          <div className="contactForms">
            <div className="contactMail">
              <form className="formulaire" onSubmit={sendEmail}>
                <input type="hidden" name="contact_number" />
                <div className="formGroup">
                  <input className="contactName" type="text" name="user_name" placeholder="Votre nom"/>
                  <input className="contactImputMail" type="email" name="user_email" placeholder="Votre email"/>
                </div>
                <textarea className="contactMessage" name="message" placeholder="Message"/>
                <button className="contactButton" type="submit">Envoyer</button>
                <p>« Aucune donnée personnelle n’est conservée par notre site via ce formulaire »</p>
                <p className="success">Votre message à bien été envoyé</p>
                <p className="error">Une erreur s'est produite veuillez renvoyer votre message ou nous recontacter ultérieurement</p>
              </form>
            </div>
            <div className="contactTelephone">
            {contact && contact.map((item, index) => (
              <Fragment>{Parser(item.text2)}</Fragment>
            ))}
            </div>
          </div>
        </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Contact;

/*export default class Contact extends React.Component {

  componentDidMount() {
    this.handlePage();
  }

  handlePage = () => {
    if (document.getElementById("contact")) {
      document.querySelector(".headerContainer").className = "headerContainer scroll";
    } else {
      document.querySelector(".headerContainer").className = "headerContainer";
    }
  };

  render() {

    function onButtonClick() {
      //e.preventDefault();
      document.querySelector(".contactName").innerHTML = "";
    }

    function sendEmail(e) {
      e.preventDefault();

      emailjs.sendForm('service_h3e321g', 'template_5y70yao', e.target, 'user_ognUqk44qPgYOk7Tb3nJ7')
        .then((result) => {
            e.target.reset();
            document.querySelector(".success").className = "success block";
            setTimeout(() => {
                document.querySelector(".success").className = "success none";
              },3000);

        }, (error) => {
            e.target.reset();
            document.querySelector(".error").className = "error block";
            setTimeout(() => {
                document.querySelector(".error").className = "error none";
              },3000);
        });
    }

    function refreshPage() {

  }

    return (
      <Fragment>
        <div className="backgroundContact">
          <div className="backgroundContactColor">
          <div className="tuileContainer" id="contact">
            <div className="ContactTitle">
              <h2>Contactez-nous</h2>
              <h3 className="none">Tech Practice</h3>
              <div className="borderBottom4"></div>
              <p>Nous vous remercions de l'intérêt que vous portez à Tech Practice. Vous pouvez nous contacter en remplissant le formulaire ci-dessous.</p>
            </div>
            <div className="contactForms">
              <div className="contactMail">
                <form className="formulaire" onSubmit={sendEmail}>
                  <input type="hidden" name="contact_number" />
                  <div className="formGroup">
                    <input className="contactName" type="text" name="user_name" placeholder="Votre nom"/>
                    <input className="contactImputMail" type="email" name="user_email" placeholder="Votre email"/>
                  </div>
                  <textarea className="contactMessage" name="message" placeholder="Message"/>
                  <button className="contactButton" type="submit">Envoyer</button>
                  <p>« Aucune donnée personnelle n’est conservée par notre site via ce formulaire »</p>
                  <p className="success">Votre message à bien été envoyé</p>
                  <p className="error">Une erreur s'est produite veuillez renvoyer votre message ou nous recontacter ultérieurement</p>
                </form>
              </div>
              <div className="contactTelephone">
                <p>Vous pouvez également nous contacter par téléphone au : <br/><a href="tel:+33679150328" className="telephone">06 79 15 03 28</a></p>
              </div>
            </div>
          </div>
          </div>
        </div>
      </Fragment>
    );
  }
}*/
